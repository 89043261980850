<template>
  <div class="start-video" v-if="startShow">
    <video ref="video" :src="video" autoplay muted ></video>
    <div class="esc">Esc退出</div>
  </div>
</template>

<script>

import Cookies from 'js-cookie'
export default {
  // props: {
  //   video: "",
  // },
  data() {
    return {
      video:require('@/assets/images/home.mp4'),
      startShow: true,
    };
  },
  watch: {
    startShow(val) {
      val
        ? window.addEventListener("keydown", this.listenEnter)
        : window.removeEventListener("keydown", this.listenEnter);
    },
  },
  beforeMount() {
    this.checkCookieAndDoTask();
   
  },
  methods: {
    listenEnter(e) {
      // console.log(e)
      if (e.keyCode == 27) {
        this.startShow = false;
        window.sessionStorage.setItem("start", 1);
        this.$emit("getEnd");
        this.$store.commit("until/SET_LOGO", true);
      }
    },
    setCookie() {
      const now = new Date();
      const expireDate = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000); // 7天后过期
      Cookies.set('myCookieName', 'myValue', { expires: expireDate });
    },
    checkCookieAndDoTask() {
      if (Cookies.get('myCookieName')) {
        // 如果cookie存在，执行相应操作

        return  this.startShow=!this.startShow
      } else {
        // 如果cookie不存在，执行另一项任务
        // this.startShow=!this.startShow
        this.setCookie();
        this.startShow = window.sessionStorage.getItem("start") ? false : true;
    if (this.startShow) {
      this.$nextTick(() => {
        let dom = this.$refs["video"];
        console.time('testForEach')
        let load = this.$loading()
        dom.addEventListener('canplaythrough',function(){
          console.timeEnd('testForEach')
          load.close();
        });

        dom.addEventListener(
          "ended",
          () => {
            //结束
            this.startShow = false;
            window.sessionStorage.setItem("start", 1);
            this.$emit("getEnd");
            this.$store.commit("until/SET_LOGO", true);
          },
          false
        );
      });
    }
    //监听esc
    window.addEventListener("keydown", this.listenEnter);
      }
    }
  
    
    
  },
};
</script>
<style lang="scss" scoped>
.start-video {
  width: 100vw;
  height: 100vh;
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .esc {
    width: 80px;
    line-height: 36px;
    background: rgba(0, 0, 0, 0.3);
    text-align: center;
    letter-spacing: 1px;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    position: absolute;
    left: 20px;
    top: 20px;
  }
}
@media (min-width: 1280px) {
  .start-video {
    width: 100vw;
    height: 100vh;
    background: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .esc {
      width: 80px;
      line-height: 36px;
      background: rgba(0, 0, 0, 0.3);
      text-align: center;
      letter-spacing: 1px;
      border-radius: 10px;
      color: #fff;
      font-size: 14px;
      position: absolute;
      left: 20px;
      top: 20px;
    }
  }
}
</style>